import styled from 'styled-components';

export const NavigationBarContainer = styled.header`
	background-color: ${({ theme }) => `${theme.primaryColor}`};

	li {
		list-style: none;
	}

	a {
		text-decoration: none;
	}
	.navbar {
		display: flex;
		align-items: center;
		padding: 1rem 1.5rem;
	}

	.hamburger {
		display: none;
	}

	.bar {
		display: block;
		width: 25px;
		height: 3px;
		margin: 5px auto;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		background-color: ${({ theme }) => `${theme.textColor}`};
	}

	.nav-menu {
		display: flex;
		align-items: center;
		> li:last-child {
			position: absolute;
			right: 5rem;
		}
	}

	.nav-item {
		margin-left: 5rem;
	}

	.nav-link {
		font-size: 1.5rem;
		font-weight: 400;
		color: ${({ theme }) => `${theme.textColor}`};
	}

	.nav-link:hover {
		text-decoration: underline;
	}

	.nav-logo {
		font-size: 1.5rem;
		font-weight: 500;
		margin-left: 5rem;
		color: ${({ theme }) => `${theme.textColor}`};
	}

	.toggle {
		display: flex;
		align-items: center;
		gap: 2rem;
		color: ${({ theme }) => `${theme.textColor}`};
		z-index: 100;
		p {
			text-transform: capitalize;
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.tablet}) {
		.nav-menu {
			padding: 0;
			z-index: 1;
			position: fixed;
			top: -300%;
			flex-direction: column;
			background-color: ${({ theme }) => `${theme.primaryColor}`};
			width: 100%;
			text-align: center;
			transition: 0.3s;
			margin: 0 -1.5rem;
			box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
			> li:last-child {
				position: static;
				margin: 2.5rem 0;
			}
		}

		.nav-menu.active {
			top: 0;
		}

		.nav-item {
			margin: 2.5rem 0;
		}

		.hamburger {
			display: block;
			cursor: pointer;
			z-index: 2;
		}

		.navbar {
			justify-content: space-between;
		}

		.hamburger.active .bar:nth-child(2) {
			opacity: 0;
		}

		.hamburger.active .bar:nth-child(1) {
			transform: translateY(8px) rotate(45deg);
		}

		.hamburger.active .bar:nth-child(3) {
			transform: translateY(-8px) rotate(-45deg);
		}

		.nav-logo {
			margin-left: 0;
		}
	}
`;
