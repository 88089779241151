import React from 'react';
import Main from './Main';
import Section from '../Section';
import About from './About';
import Contact from './Contact';
import Skills from './Skills';

export default () => (
	<>
		<Section height="calc(100vh - 94px)" id="main">
			<Main />
		</Section>
		<Section id="about">
			<About />
		</Section>
		<Section id="skills" isAlt>
			<Skills />
		</Section>
		<Section id="contact">
			<Contact />
		</Section>
	</>
);
