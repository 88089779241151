export default {
	light: {
		primaryColor: '#f9fafe',
		primaryColorAlt: '#eaecf4',
		containerColor: 'white',
		containerShadow: '0 16px 40px 0 #E8EAF3',
		textColor: 'black',
		captionColor: '#303030',
		textColorAlt: '#303030',
		mobile: '768px',
		tablet: '1024px',
		smallMonitor: '1300px',
		borderColor: '#5d5d5d',
	},
	dark: {
		primaryColor: '#303030',
		primaryColorAlt: '#212121',
		containerColor: '#2A2A2A',
		containerShadow: '0 27px 20px -16px rgba(26,26,26,0.65)',
		textColor: 'white',
		captionColor: 'white',
		textColorAlt: 'white',
		mobile: '768px',
		tablet: '1024px',
		smallMonitor: '1300px',
		borderColor: '#e6e6e6',
	},
};
