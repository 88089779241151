import styled from 'styled-components';

const SectionContainer = styled.section`
	background-color: ${({ theme, isAlt }) => `${isAlt ? theme.primaryColorAlt : theme.primaryColor}`};
	color: ${({ theme }) => `${theme.textColor}`};
	margin: 0 3rem;
	> div {
		width: 65vw;
		margin: 0 auto;
		padding: 2rem 0;
		${({ height }) => height && `height: ${height}`};
	}
	@media only screen and (max-width: 1440px) {
		margin: 0;
		> div {
			width: 75vw;
		}
	}
	@media only screen and (max-width: ${({ theme }) => theme.mobile}) {
		> div {
			width: 90vw;
			padding: 1rem 0;
		}
	}
`;

const Section = ({ children, height, isAlt = false }) => {
	return (
		<SectionContainer isAlt={isAlt} height={height}>
			<div>{children}</div>
		</SectionContainer>
	);
};

export default Section;
