export const TOGGLE_THEME = 'toggle.theme';

export default (state, action) => {
	switch (action.type) {
		case TOGGLE_THEME: {
			return {
				...state,
				theme: state.theme === 'dark' ? 'light' : 'dark',
			};
		}
		default:
			throw new Error('Action not defined');
	}
};
