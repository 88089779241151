import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { getStorage } from 'firebase/storage';
import Container from '../Container';
import GridContent from '../GridContent';
import SectionName from '../Section/SectionName';
import Skill from '../Skill';
import firebaseApp from '../../firebaseApp';

const Language = styled.div`
	display: flex;
	alignitems: baseline;
`;

const Skills = styled.div`
	margin: 3rem 0;
`;

const database = firebaseApp.database();
export default () => {
	const [skills, setSkills] = useState([]);

	useEffect(() => {
		const ref = database.ref('skills');
		ref.once('value').then(snapshot => {
			setSkills(snapshot.val());
		});
	}, []);
	return (
		<>
			<br />
			<SectionName id="skills">Skills</SectionName>
			<br />
			<Skills id="skills_tech_stack">
				<h2>Tech stack</h2>
				<br />
				<GridContent cols={5} mobileCols={2} tabletCols={3} smallMonitorCols={4} gap="2rem" space="1fr">
					{skills.map(skill => (
						<Skill key={skill.name}>
							<img height="30" alt="logo" src={skill.image} />
							<p className="body1">{skill.name}</p>
						</Skill>
					))}
				</GridContent>
			</Skills>
			<br />
			<div id="skills_other">
				<h2>Other</h2>
				<Container>
					<GridContent cols={2} gap="2rem">
						<p className="body1">git (Github, Gitlab, Bitbucket)</p>
						<p className="body1">VS Code</p>
						<p className="body1">Agile process development</p>
						<p className="body1">JIRA</p>
						<p className="body1">IntelliJ/Eclipse IDE</p>
						<p className="body1">Linux, macOS and Windows environments</p>
					</GridContent>
				</Container>
			</div>
			<br />
			<div id="skills_languages">
				<h2>Languages</h2>
				<Container>
					<GridContent cols={2}>
						<Language>
							<p className="body1">Serbian</p>
							<p className="caption">&nbsp;- native</p>
						</Language>
						<Language>
							<p className="body1">English</p>
							<p className="caption">&nbsp;- fluent</p>
						</Language>
					</GridContent>
				</Container>
			</div>
		</>
	);
};
