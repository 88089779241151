import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	box-shadow: ${({ theme }) => `${theme.containerShadow}`};
	padding: 3rem;
	background-color: ${({ theme }) => `${theme.containerColor}`};
	margin: 3rem 0;
	position: relative;
`;

export default ({ children }) => <Container>{children}</Container>;
