import React, { useMemo, useReducer, createContext } from 'react';
import stateReducer from './stateReducer';

export const StateContext = createContext();

const initialState = {
	theme: 'dark',
};

export default ({ children }) => {
	const [state, dispatch] = useReducer(stateReducer, initialState);
	const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
	return <StateContext.Provider value={contextValue}>{children}</StateContext.Provider>;
};
