import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 100;
    font-family: 'Montserrat', sans-serif;
    background-color: ${({ theme }) => `${theme.primaryColor}`};
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  p, h1, h2 {
    margin: 0;
  }
  h1 {
    font-size: 5rem;
    font-weight: bold;
    color: ${({ theme }) => `${theme.textColor}`};
  }
  h2 {
    font-size: 1.5rem;
    color: ${({ theme }) => `${theme.textColor}`};
  }
  h3 {
    font-size: 1.3rem;
    color: ${({ theme }) => `${theme.textColor}`};
  }
  .caption {
    color: ${({ theme }) => `${theme.captionColor}`};
    opacity: 0.4;
    margin-bottom: 0.5rem;
  }
  .body1 {
    font-size: 1.1rem;
    color: ${({ theme }) => `${theme.textColor}`};
  }
  .body2 {
    color: ${({ theme }) => `${theme.textColorAlt}`};
    opacity: 0.7;
  }
`;
