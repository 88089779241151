import styled from 'styled-components';

export default styled.div`
	position: absolute;
	left: 5rem;
	font-size: 4rem;
	font-weight: bold;
	@media only screen and (max-width: 1500px) {
		position: static;
	}
`;
