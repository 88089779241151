import React from 'react';
import styled from 'styled-components';

const Circles = styled.div`
	position: absolute;
	top: 15%;
	left: 60%;
	width: 1000px;
	opacity: 0.5;

	@media only screen and (max-width: ${({ theme }) => theme.mobile}) {
		display: none;
	}

	img {
		position: absolute;
	}

	.circle1 {
		animation-name: spin;
		animation-duration: 60000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	.circle2 {
		animation-name: spin;
		animation-duration: 50000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	.circle3 {
		animation-name: spin;
		animation-duration: 40000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	.circle4 {
		animation-name: spin;
		animation-duration: 55000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	.circle5 {
		animation-name: spin;
		animation-duration: 65000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	.circle6 {
		animation-name: spin;
		animation-duration: 60000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	.circle7 {
		animation-name: spin;
		animation-duration: 45000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

export default () => (
	<Circles>
		<img src="./circles/Circle_illustration_1-Outer.svg" className="circle1" alt="circle1" />
		<img src="./circles/Circle_illustration_2.svg" className="circle2" alt="circle2" />
		<img src="./circles/Circle_illustration_3.svg" className="circle3" alt="circle3" />
		<img src="./circles/Circle_illustration_4.svg" className="circle4" alt="circle4" />
		<img src="./circles/Circle_illustration_5.svg" className="circle5" alt="circle5" />
		<img src="./circles/Circle_illustration_6.svg" className="circle6" alt="circle6" />
		<img src="./circles/Circle_illustration_7-Inner.svg" className="circle7" alt="circle7" />
	</Circles>
);
