import React from 'react';
import Content from './components/Content';
import GlobalStyle from './GlobalStyle';
import NavigationBar from './components/NavigationBar';
import StateProvider from './state/StateProvider';
import AppContainer from './AppContainer';

const App = () => (
	<StateProvider>
		<AppContainer>
			<GlobalStyle />
			<NavigationBar />
			<Content />
		</AppContainer>
	</StateProvider>
);

export default App;
