import styled, { css } from 'styled-components';

export default styled.div`
	display: grid;
	grid-template-columns: ${({ cols, space }) => `repeat(${cols || 1}, minmax(0, ${space || 'auto'}))`};
	${({ width }) =>
		width &&
		css`
			width: ${width};
			.caption {
				margin: 0;
			}
		`};
	${({ gap }) =>
		gap &&
		css`
			gap: ${gap};
		`};
	${({ margin }) =>
		margin &&
		css`
			margin: ${margin};
			@media only screen and (max-width: ${({ theme }) => theme.mobile}) {
				margin: 0 auto;
				width: 100%;
				// text-align: center;
			}
		`};
	@media only screen and (max-width: ${({ theme }) => theme.smallMonitor}) {
		grid-template-columns: ${({ smallMonitorCols, space }) =>
			`repeat(${smallMonitorCols || 1}, ${space || 'auto'})`};
	}
	@media only screen and (max-width: ${({ theme }) => theme.tablet}) {
		grid-template-columns: ${({ tabletCols, space }) => `repeat(${tabletCols || 1}, ${space || 'auto'})`};
	}
	@media only screen and (max-width: ${({ theme }) => theme.mobile}) {
		grid-template-columns: ${({ mobileCols, space }) => `repeat(${mobileCols || 1}, ${space || 'auto'})`};
	}
`;
