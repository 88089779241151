import styled from 'styled-components';
import GridContent from '../GridContent';
import Logo from '../Logo';

const Flex = styled.div`
	display: flex;
	@media only screen and (max-width: ${({ theme }) => theme.mobile}) {
		flex-direction: column;
	}
`;

const Social = styled.div`
	display: flex;
	align-items: baseline;
`;

const Container = styled.div`
	margin-top: 7rem;
	a {
		text-decoration: none;
	}
	a:hover {
		opacity: 0.8;
	}
`;

const ContactInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	img {
		width: fit-content;
	}
	@media only screen and (max-width: 1500px) {
		margin-bottom: 5rem;
	}
`;

const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 5rem;
	p {
		margin-bottom: 0;
	}
	a {
		margin: 0 0.5rem;
		img {
			padding: 0.5rem;
			border: 1px solid ${({ theme }) => `${theme.borderColor}`};
			opacity: 0.6;
			height: 34px;
		}
		img:hover {
			opacity: 1;
		}
	}
`;

const currentYear = new Date().getFullYear();
export default () => (
	<Container>
		<Flex>
			<ContactInfo>
				<Logo />
			</ContactInfo>
			<GridContent cols={3} gap="0.5rem" width="50%" margin="0 0 0 auto" mobileCols={3}>
				<p className="body1">About</p>
				<p className="body1">Skills</p>
				<p className="body1">Contact</p>
				<a href="#about_education" className="caption">
					Education
				</a>
				<a href="#skills_tech_stack" className="caption">
					Tech stack
				</a>
				<a href="mailto: kajtes.luka@gmail.com" className="caption">
					kajtes.luka@gmail.com
				</a>
				<a href="#about_experience" className="caption">
					Experience
				</a>
				<a href="#skills_other" className="caption">
					Other
				</a>
				<a href="./Luka_Kajtes_CV.pdf" className="caption">
					Download CV
				</a>
				<a href="#about_certifications" className="caption">
					Certifications
				</a>
				<a href="#skills_languages" className="caption">
					Languages
				</a>
			</GridContent>
		</Flex>
		<Footer>
			<p className="caption">Copyright © Luka Kajtes {currentYear}</p>
			<Social>
				<a href="https://www.linkedin.com/in/luka-kajtes-3b6b0139/">
					<img src="./linkedin.svg" alt="Linkedin" />
				</a>
				<a href="skype:lukaans?userinfo">
					<img src="./skype.svg" alt="Skype" />
				</a>
				<a href="https://github.com/luk492">
					<img src="./github.svg" alt="Github" />
				</a>
				<a href="https://bitbucket.org/lukakajtes/">
					<img src="./bitbucket.svg" alt="Bitbucket" />
				</a>
			</Social>
		</Footer>
	</Container>
);
