import styled from 'styled-components';
import Circles from '../Circles';

const Main = styled.div`
	height: calc(100% - 13rem);
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 60%;
	h2 {
		font-weight: 100;
		line-height: 33px;
		width: 70%;
	}
	@media only screen and (max-width: ${({ theme }) => theme.tablet}) {
		padding: 10rem 0 0 0;
		height: calc(100% - 16rem);
		h2 {
			width: 100%;
		}
	}
`;

export default () => (
	<Main>
		<h1>Welcome</h1>
		<br />
		<h2>
			Here you can find some of the projects I&apos;ve done and am working on. <b>Feel free to look around!</b>
		</h2>
		<Circles />
	</Main>
);
