import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { StateContext } from './state/StateProvider';
import themes from './themes';

const AppContainer = ({ children }) => {
	const {
		state: { theme },
	} = useContext(StateContext);
	return <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>;
};

export default AppContainer;
