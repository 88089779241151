import React from 'react';
import styled from 'styled-components';
import Container from '../Container';
import GridContent from '../GridContent';
import SectionName from '../Section/SectionName';

const About = styled.div`
	h3 {
		line-height: 28px;
	}
	.body1 {
		margin-bottom: 0.5rem;
	}
	.body2 {
		line-height: 22px;
	}
`;

const GridItemSpanTwoColumns = styled.div`
	grid-column-start: 1;
	grid-column-end: 3;
`;

export default () => (
	<About>
		<SectionName id="about">About</SectionName>
		<div>
			<h3>
				I am passionate about technology. Love to learn new stuff about javascript. <br />
				In my spare time I like to hang out with my friends and family. <br />
				My hobby is swimming and woodworking.
			</h3>
			<Container>
				<div id="about_education">
					<h2>Education</h2>
					<br />
					<GridContent cols={2} gap="2rem">
						<div>
							<p className="caption">2011 - 2015</p>
							<p className="body1">Bachelor degree in software engineering</p>
							<p className="body2">Novi Sad, Serbia</p>
							<p className="body2">Faculty of Technical Sciences</p>
						</div>
						<div>
							<p className="caption">2007 - 2011</p>
							<p className="body1">Graduated as a computer technician</p>
							<p className="body2">Novi Sad, Serbia</p>
							<p className="body2">High School of Electrotechnics</p>
						</div>
					</GridContent>
				</div>
			</Container>
			<Container>
				<div id="about_experience">
					<h2>Experience</h2>
					<br />
					<h3>HTEC</h3>
					<GridContent cols={3} space="1fr">
						<GridItemSpanTwoColumns>
							<p className="caption">2021 - present</p>
							<p className="body1">Software Engineer</p>
							<p className="body2">
								Working with these technologies: ReactJS, React Context and/or Redux as state
								management, Typescript, Formik, maps(leafletjs), MaterialUI, Styled components,
								Storybook, bit(dev) as a component library, bitbucket… Consuming API’s via REST/GraphQL
							</p>
						</GridItemSpanTwoColumns>
					</GridContent>
					<br />
					<br />
					<h3>Synechron</h3>
					<GridContent cols={3} gap="2rem">
						<div>
							<p className="caption">2020 - 2021</p>
							<p className="body1">Full Stack Javascript Developer</p>
							<p className="body2">
								Development of a messaging platform for a french startup in the financial business.
								Worked with the newest versions of these technologies: React, Node.js, GraphQL, MongoDB,
								CodeceptJS with Gherkin for E2E tests, Jest, git (Gitlab)
							</p>
						</div>
						<div>
							<p className="caption">2018 - 2020</p>
							<p className="body1">Web Developer</p>
							<p className="body2">
								Worked on a wealth management web application for an American client. I worked with
								these technologies: Angular(5+), Rxjs, SASS, Karma and Jasmine test environment,
								Consuming REST API which is based in a Ruby back-end, git(Github)
							</p>
						</div>
						<div>
							<p className="caption">2015 - 2018</p>
							<p className="body1">Full Stack Java Developer</p>
							<p className="body2">
								Working on development and integration of a web application which clients from insurance
								businesses based in France use. I worked with these technologies: Java EE, Spring,
								Oracle SQL, Hibernate, Javascript (AngularJS, Dojo), HTML, JSP, CSS, Bootstrap,
								Cucumber, JUnit, Mockito, Spring batch, git (Gitlab), Agile Development Process, JIRA,
								Jenkins
							</p>
						</div>
					</GridContent>
					<br />
					<br />
					<h3>Freelance</h3>
					<GridContent cols={3} space="1fr">
						<GridItemSpanTwoColumns>
							<p className="caption">2017 - present</p>
							<p className="body2">
								Involves developing deployment ready web applications in latest technology stacks such
								as ReactJS, Angular, NodeJS, MongoDB, Rxjs, Redux, Ngrx, SASS etc.
							</p>
						</GridItemSpanTwoColumns>
						<div />
						<div />
					</GridContent>
				</div>
			</Container>
			<Container>
				<div id="about_certifications">
					<h2>Certifications</h2>
					<br />
					<br />
					<GridContent cols={3} gap="2rem">
						<div>
							<p className="body1">Cambridge English: First (FCE)</p>
							<p className="caption">Cambridge English Language Assessment</p>
						</div>
						<div>
							<p className="body1">React - The Complete Guide (incl Hooks, React Router, Redux)</p>
							<p className="caption">Udemy. Credential ID: UC-3Q67J7PR</p>
						</div>
						<div>
							<p className="body1">JavaScript - Understanding the Weird Parts</p>
							<p className="caption">Udemy. Credential ID: UC-CJEQ5Q8W</p>
						</div>
						<div>
							<p className="body1">RxJs 6 In Practice</p>
							<p className="caption">Udemy. Credential ID: UC-GJB1NOPM</p>
						</div>
						<div>
							<p className="body1">Understanding TypeScript</p>
							<p className="caption">Udemy. Credential ID: UC-MEML4YGP</p>
						</div>
						<div>
							<p className="body1">NgRx In Depth (Angular 7 and NgRx 6)</p>
							<p className="caption">Udemy. Credential ID: UC-E3LEOXOJ</p>
						</div>
						<div>
							<p className="body1">The Complete Node.js Developer Course (2nd Edition)</p>
							<p className="caption">Udemy. Credential ID: UC-UVLG8T0Z</p>
						</div>
						<div>
							<p className="body1">Front-End JavaScript Frameworks: Angular</p>
							<p className="caption">Coursera. Credential ID: A6RCTV92CFGN</p>
						</div>
					</GridContent>
				</div>
			</Container>
		</div>
	</About>
);
