import styled from 'styled-components';

const Skill = styled.div`
	height: 69px;
	background-color: ${({ theme }) => `${theme.containerColor}`};
	display: flex;
	align-items: center;
	gap: 1rem;
	padding-left: 2rem;
	margin-top: 2rem;
	box-shadow: ${({ theme }) => `${theme.containerShadow}`};
`;

export default ({ children }) => <Skill>{children}</Skill>;
