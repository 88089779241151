import { useContext, useEffect } from 'react';
import { StateContext } from '../../state/StateProvider';
import { TOGGLE_THEME } from '../../state/stateReducer';
import Logo from '../Logo';
import Toggle from '../ThemeToggle/Toggle.styled';
import { NavigationBarContainer } from './NavigationBar.styled';

const NavigationBar = () => {
	const {
		state: { theme: currentTheme },
		dispatch,
	} = useContext(StateContext);

	const toggleTheme = () => dispatch({ type: TOGGLE_THEME });

	const mobileMenu = () => {
		document.querySelector('.hamburger')?.classList?.toggle('active');
		document.querySelector('.nav-menu')?.classList?.toggle('active');
	};

	const closeMenu = () => {
		document.querySelector('.hamburger')?.classList?.remove('active');
		document.querySelector('.nav-menu')?.classList?.remove('active');
	};

	useEffect(() => {
		const navLink = document.querySelectorAll('.nav-link');
		navLink.forEach(n => n.addEventListener('click', closeMenu));
		return () => {
			navLink.forEach(n => n.removeEventListener('click', closeMenu));
		};
	}, []);

	return (
		<NavigationBarContainer>
			<nav className="navbar">
				<a href="/" className="nav-logo">
					<Logo />
				</a>
				<ul className="nav-menu">
					<li className="nav-item">
						<a href="#about" className="nav-link">
							About
						</a>
					</li>
					<li className="nav-item">
						<a href="#skills" className="nav-link">
							Skills
						</a>
					</li>
					<li className="nav-item">
						<a href="mailto: kajtes.luka@gmail.com" className="nav-link">
							Email me
						</a>
					</li>
					<li className="toggle">
						<Toggle toggle={toggleTheme} />
						<p className="body2">{currentTheme}</p>
					</li>
				</ul>
				<div className="hamburger" role="button" onClick={mobileMenu} onKeyDown={mobileMenu} tabIndex={0}>
					<span className="bar" />
					<span className="bar" />
					<span className="bar" />
				</div>
			</nav>
		</NavigationBarContainer>
	);
};

export default NavigationBar;
